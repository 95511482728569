import { ConfigProvider, Radio, en_US, getLang, getLocale, zh_CN, Segmented} from '@didi/ec-base';
import { Intl, utils } from '@didi/pagelets-sdk';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import manifest from '../manifest.json';
import {
  EmptyRender,
  ExportButton
} from './components';
import Table from './components/Table';
import {
  columns,
  columnsTemp,
  exportUrls,
  optionUrls,
  url,
  urlTemp,
} from './helper/tableOptions';
import { getFunctionId } from './utils/config';
import newRequest from './utils/newRequest';
const localLocales = manifest.locales || {};

const monthFormat = 'YYYY-MM';
const offBoardingTypeObj = {
  management: 'manager',
  'hr-workspace': 'hrbp',
};

// 校招社招这个参数不同
const onBoardingType = [
  { label: '全部', value: 'all' },
  { label: '社招', value: 'social' },
  { label: '校招', value: 'campus' },
];

// 接口参数
const ParamsData = {
  today: {
    title: '今天预计入职',
    type: 'todayIsExpectedToHireEmployees',
    option: 'todayIsExpectedOptionUrl',
    textType: 0,
    defaultVal: null,
    exportUrl: 'todayExpectedEntryPeople',
    excelName: 'EXPORT_EXPECTED_TODAY_LIST', // '导出今天预计入职人员名单',
    elementTag: 'page_entry_expectation_employees',
  },
  tomorrow: {
    title: '在途人员',
    type: 'tomorrowToJoinEmployees', // 请求接口url类型
    option: 'tomorrowToJoinOptionUrl', // 选项接口URl
    textType: 1, // 显示类型 0、-1:无；1:文字提示；其他：输入框
    defaultVal: '0', // 默认选中的tab
    exportUrl: 'inTransitPeople', // 导出url
    defaultRecuitType: 'all', // 默认查看所有类型（校招、社招）
    excelName: 'EXPORT_PERSONNEL_TRANSIT', // 导出excel名称 导出在途人员名单
    elementTag: 'page_entry_ontheway_employees',
  },
  offBoarding: {
    title: '预计离职人员',
    type: 'offBoarding',
    option: 'offBoarding',
    textType: -2,
    defaultVal: null,
    exportUrl: 'offBoarding',
    excelName: 'EXPORT_EXPECTED_RESIGNATIONS', // 导出预计离职人员名单
    elementTag: 'page_leave_expectation_employees',
  },
};

const styles = {
  marginRight8: {
    marginRight: '8px',
  },
  marginLeft8: {
    marginLeft: '8px',
  },
  marginBottom16: {
    marginBottom: '16px',
  },
};

import useStyle from './style';

const Pagelet = (props) => {
  const {
    params: {
      auth,
      headers = {},
      apiHost,
      locales,
      type, // 在途人数、入职人员、预计离职人员的标识
      boardingType, // 全部、社招、校招
      deptCodes = [],
    }
  } = props;

  const onTheWayParams = ParamsData[type];
  const authId = utils.getFunctionId(auth, onTheWayParams.elementTag);
  const newHeaders = { ...headers, 'bricks-function-id': authId };
  const request = newRequest.init(newHeaders);

  // 多语言优先 props，再取本地
  const langLocales = locales || localLocales;
  const locale = getLocale(langLocales);
  const intl = Intl(locale);
  const langEn = getLang()?.toLowerCase().includes('us');


  const [columnsData, setColumnsData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [defaultChoice, setDefaultChoice] = useState(onTheWayParams.defaultVal);
  const [requestUrl, setRequestUrl] = useState('');
  const [excelNameTemp, setExcelNameTemp] = useState(intl(onTheWayParams.excelName));
  const [sortedInfo, setSortedInfo] = useState({});
  const [date, setDate] = useState(moment().format(monthFormat));
  const [tableWidth, setTableWidth] = useState(0);

  const [onBoardingTypeValue, setOnBoardingTypeValue] = useState(boardingType || onTheWayParams.defaultRecuitType);
  const tableRef = useRef(null);

  const [page, setPage] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  });

  // 获取表格数据
  const loadDataFunc = async (params) => {
    const { pageNum, pageSize } = params;
    const { columnKey, order } = params.sorter;
    const orderTemp = order === 'descend' ? 'desc' : 'asc';

    // 重置排序
    setSortedInfo(params.sorter);

    // 请求表格数据
    // timeType为在途人员入参
    // handleStatus为今日预计入职人员入参
    let data = {
      pageNum,
      pageSize,
      deptCodes
    };
    // 当前选择的是今日，排序：部门,姓名。
    // 当前选择的是明日在途，排序：部门,计划入职日期
    if (onTheWayParams.textType === 0 || onTheWayParams.textType === 1) {
      data = {
        ...data,
        timeType: defaultChoice,
        handleStatus: defaultChoice,
        entryDeptPath:
          columnKey === 'entryDeptPath' && order ? orderTemp : undefined,
        entryDate: columnKey === 'entryDate' && order ? orderTemp : undefined,
        name: columnKey === 'name' && order ? orderTemp : undefined,
        recuitType: onBoardingTypeValue,
      };
    }
    // 预计离职人员
    if (onTheWayParams.textType === -2 && authId) {
      const {
        deptCodes, pageSize, pageNum, pageCode, functionId,
      } = data;
      const depatStr = deptCodes.length === 0 ? '' : deptCodes;
      const res = await request({
        method: 'post',
        url: `${apiHost}${url[type]}`,
        data: {
          deptCodes: depatStr,
          pageSize,
          pageNum,
        },
        headers: {
          functionId: authId,
        }
      });
      setPage({
        ...data,
        total: res.data?.total || 0,
      });
      return res;
    }

    if (authId) {
      const res = await request({
        method: 'post',
        url: `${apiHost}${requestUrl || url[onTheWayParams.type]}`,
        data,
        headers: {
          functionId: authId,
        }
      });
      setPage({
        ...data,
        total: res.data?.total || 0,
      });
      return res;
    }
  };

  // 获取选项
  const getOption = async () => {
    const { data } = await request(`${apiHost}${optionUrls[onTheWayParams.option]}`);
    let onTheWayParamsList = [];
    data.map((item) => {
      onTheWayParamsList.push({
        label: item?.name,
        value: item?.code,
      });
    });
    setOptionsData(onTheWayParamsList);
  };

  // 请求数据
  const postData = {
    ...page,
    pageNum: 1,
    pageSize: page.total || 10,
    functionId: getFunctionId(
      window.location.pathname.split('/')[
        window.location.pathname.split('/').length - 1
      ],
    ),
  };
  // 离职人数导出请求数据
  const offboardingData = {
    deptCodes: page.deptCodes?.length === 0 ? '' : deptCodes,
    type: offBoardingTypeObj[page.pageCode],
    pageNum: 1,
    pageSize: page.total || 10,
    functionId: getFunctionId(
      window.location.pathname.split('/')[
        window.location.pathname.split('/').length - 1
      ],
    ),
  };

  const onChange = (value) => {
    if (urlTemp.indexOf(url[onTheWayParams.type]) !== -1) {
      setRequestUrl(`${apiHost}${urlTemp[Number(value) - 1]}`);
      setExcelNameTemp(`员工${optionsData[Number(value) - 1].name}名单`);
    }
    setDefaultChoice(value);
  };

  const onBoardingTypeChange = (e) => {
    setOnBoardingTypeValue(e);
  };

  useEffect(() => {
    if (onTheWayParams.textType !== -1 && onTheWayParams.textType !== -2) getOption();
  }, []);

  useEffect(() => {
    tableRef.current.loadData({ current: 1 }, 1);
  }, [onBoardingTypeValue]);

  // 添加可控的排序，在切换的时候清除排序
  const resetColumns = (col) => col.map((v) => {
    if (v.sorter) {
      return {
        ...v,
        sortOrder: sortedInfo.columnKey === v.key && sortedInfo.order,
      };
    } return v;
  });
  useEffect(() => {
    const columnsData = columns(intl);
    let columns2 = columnsData[onTheWayParams.type];

    if (urlTemp.indexOf(url[onTheWayParams.type]) !== -1) {
      const columsTempData = columnsTemp(intl);
      columns2 = columsTempData[Number(defaultChoice) - 1];
    }
    let width = 0;
    columns2.map((v) => {
      width += v.width;
    });
    setTableWidth(width);
    setColumnsData(resetColumns(columns2));
  }, [sortedInfo]);

  const prefixCls = 'pagelet-ontheway';
  const wrapSSH = useStyle(prefixCls);

  return wrapSSH(
    <ConfigProvider prefixCls="ant5" locale={langEn ? en_US : zh_CN}>
      <div className={prefixCls}>
        <div className="ontheway-search">
          {onTheWayParams.textType !== -2 && (
            <div className="ontheway-tab">
              {onTheWayParams.textType === 1 && (
                <>
                <Segmented
                  value={onBoardingTypeValue}
                  options={onBoardingType}
                  onChange={onBoardingTypeChange}
                />
              </>
              )}

              {(onTheWayParams.textType === 0 || onTheWayParams.textType === 1) && (
                <>
                  <Segmented
                    value={defaultChoice}
                    options={optionsData}
                    onChange={onChange}
                  />
                </>
              )}
            </div>
          )}

          {onTheWayParams.textType === -2 ? (
            <ExportButton
              data={offboardingData}
              url={`${apiHost}${exportUrls[onTheWayParams.exportUrl]}`}
              defaultFile={excelNameTemp}
              authId={authId}
              className="export-button"
              intl={intl}
              headers={newHeaders}
            />
          ) : (
            <ExportButton
              data={postData}
              url={
                onTheWayParams.textType === 0 || onTheWayParams.textType === 1
                  ? `${apiHost}${exportUrls[onTheWayParams.exportUrl]}`
                  : `${apiHost}gateway/dhr-workbench${exportUrls[onTheWayParams.exportUrl]}`
              }
              className="export-button"
              authId={authId}
              defaultFile={excelNameTemp}
              date={`${date.split('-')[0]}_${date.split('-')[1]}`}
              intl={intl}
              headers={newHeaders}
            />
          )}
        </div>

        <EmptyRender intl={intl}>
          <Table
            locale={langEn ? en_US : zh_CN}
            columns={columnsData}
            loadDataFunc={loadDataFunc}
            scroll={{
              x: tableWidth,
              y: 'calc(100vh - 222px)',
            }}
            defaultChoice={defaultChoice}
            ref={tableRef}
            date={date}
            intl={intl}
          />
        </EmptyRender>
      </div>
    </ConfigProvider>
  )
}

export default Pagelet;
